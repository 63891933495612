import React from 'react';
import { Modal, Grid } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTheme } from 'styled-components';
import { Container, Header, List, ListItem } from './style';
import {IInfo_ativo} from '../../../../services/reducers/cartExpSlice'

interface ModalProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  data: IInfo_ativo[] | null;
  dataSelecionada: string | undefined;
}

export default function ModalInfo({
  openModal,
  setOpenModal,
  data,
  dataSelecionada,
}: ModalProps) {
  const theme = useTheme();
  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Container containerHeight={500}>
        <Header>
          <div>Informações da explosão em {dataSelecionada}<br/>
          <span style={{fontWeight: "bold"}}>Fundos explodidos:</span></div>

          <Close
            onClick={() => setOpenModal(false)}
            sx={{
              cursor: 'pointer',
              width: 20,
              [`@media ${theme.device.mobileXL}`]: {
                width: 15,
              },
              [`@media ${theme.device.mobileM}`]: {
                width: 10,
              },
            }}
          />
        </Header>
        <Grid container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {data && data.length > 0 ? (
          <List>
            {data.map((item, index) => (
              <ListItem key={index}>
                {item?.alias} <br></br>
                ({item?.ativo})
              </ListItem>
            ))}
          </List>
        ) : <p>Nenhuma informação disponível para explosão</p>}
      </Grid>
      </Container>
    </Modal>
  );
}