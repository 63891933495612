import { useState, useEffect } from 'react';
import {ICarteira} from '../../services/reducers/preferenceSlice';

/**
 * Obter o Prefixo Monetário da carteira selecionada
 * @param carteiraList Lista das carteiras do usuário
 * @param carteira Nome da carteira
 * @returns Prefixo monetário da carteira
 */
const MoneyPrefix = (carteiraList: Array<ICarteira>, carteira: string | null) => {
    const [moneyPrefix, setMoneyPrefix] = useState<string | null>(null);
    useEffect(() => {
        if (carteiraList && carteiraList[0] && carteira){
            const carteira_result = carteiraList.filter((item) => item.nome_portfolio.toUpperCase() === carteira.toUpperCase());
            if(carteira_result[0]){
                if(carteira_result[0].unidade_monetaria === 'EUR' || carteira_result[0].moeda === '&#8364'){
                    setMoneyPrefix("€");
                }else{
                    setMoneyPrefix(carteira_result[0].moeda);
                }
            }
        }
    }, [carteiraList, carteira]);
    return moneyPrefix;
}

export default MoneyPrefix