import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../services/reduxHooks';

import React, { useEffect, useState } from 'react';
import { fetchFluxoAtivos } from '../../../services/api/fluxoAtivosAPI';
import { fetchPosCon } from '../../../services/api/posConAPI';
// import { fetchCartExp } from '../../../services/api/cartExpAPI';
import { fetchResumoCart, fetchResumoCartPerf } from '../../../services/api/resumoCartAPI';
import { fetchPerfHist } from '../../../services/api/perfHistAPI';
import { fetchEstats } from '../../../services/api/estatsAPI';
import { fetchCarteiraList } from '../../../services/api/preferenceAPI';

import { setSelectedBenchmarks as setSelectedBenchmarksResumoCart, setIsPercentToggle } from '../../../services/reducers/resumoCartSlice';
import { setSelectedBenchmarks as setSelectedBenchmarksPerfHist } from '../../../services/reducers/perfHistSlice';

import GerarPDFTodos from '../GerarPDFTodos/index';

/**
 * Gerar o PDF via URL
 *
 * Os poss�veis par�metros s�o:
 * - carteira (ex: ExApp1)
 * - periodo (ex: periodo=12m)
 * - benchmarks_resumo_cart (ex: benchmarks_resumo_cart=cdi+ibov)
 * - benchmarks_perf_hist (ex: benchmarks_resumo_cart=cdi+ibov)
 * - percent_toggle (ex: percent_toggle=1)
 *
 * ex: extratos.comdinheiro.com.br/AgendamentoPDF?carteira=ExApp1&periodo=maximo
 */
export default function AgendamentoPDF() {
    const dispatch = useAppDispatch();

    const [
        loadedCarteiraList
    ] = useAppSelector((state) => [
        state.preference.loadedCarteiraList
    ]);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    /**
     * Parametros na URL:
     * - carteira (ex: ExApp1)
     * - periodo (ex: periodo=12m)
     * - benchmarks_resumo_cart (ex: benchmarks_resumo_cart=cdi+ibov)
     * - benchmarks_perf_hist (ex: benchmarks_resumo_cart=cdi+ibov)
     * - percent_toggle (ex: percent_toggle=1)
     */
    const [carteiraSelecionada, setCarteiraSelecionada] = useState<string | null>(searchParams.get('carteira'));
    if(!carteiraSelecionada){
        return (
            <div>Nenhuma carteira selecionada</div>
        );
    }
    const [periodo, setPeriodo] = useState<string | null>(searchParams.get('periodo'));
    const [isPercentToggle] = useState<boolean>(true);  // azimut sempre � em porcentagem

    // setar os benchmarks passados como parametro
    const dispatchSelectedBenchmarksResumoCart = (newBenchmarks: string[]) => dispatch(setSelectedBenchmarksResumoCart(newBenchmarks));
    const dispatchSelectedBenchmarksPerfHist = (newBenchmarks: string[]) => dispatch(setSelectedBenchmarksPerfHist(newBenchmarks));
    useEffect(() => {
        const newBenchmarksResumoCart = searchParams.get('benchmarks_resumo_cart')?.split(' '); // se n�o passar par�metro vale undefined
        const newBenchmarksPerfHist = searchParams.get('benchmarks_perf_hist')?.split(' '); // se n�o passar par�metro vale undefined
        if (newBenchmarksResumoCart) {
            dispatchSelectedBenchmarksResumoCart(newBenchmarksResumoCart);
        }
        if (newBenchmarksPerfHist) {
            dispatchSelectedBenchmarksPerfHist(newBenchmarksPerfHist);
        }
    }, [loadedCarteiraList]);

    const [isFetchLoaded, setIsFetchLoaded] = useState(false);  // indica se os dados ja foram carregados
    // carregar os dados da API
    useEffect(() => {
        const fetchDataAPI = async () => {
            try {
                setTimeout(async () => {
                    const dispatchArray: any[] = [
                        dispatch(fetchCarteiraList()),
                        dispatch(fetchResumoCart()),
                        dispatch(fetchPerfHist()),
                        dispatch(fetchPosCon()),
                        // dispatch(fetchCartExp()),
                        dispatch(fetchFluxoAtivos('mes_atual')),
                        dispatch(fetchEstats())
                    ];
                    if (isPercentToggle) {
                        dispatchArray.push(dispatch(fetchResumoCartPerf()), dispatch(setIsPercentToggle(isPercentToggle)));
                    }
                    await Promise.all(dispatchArray);   // espera todos os fetch terminarem
                    setIsFetchLoaded(true);
                }, 0);  // timeout de 0 para executar depois das outras fun��es na stack
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchDataAPI();
    }, [dispatch]);

    return (
        <div>{isFetchLoaded ? (<GerarPDFTodos isViaURL={1}/>):(<div>Loading...</div>)}</div>
    );
}
