import React from 'react';
import { Modal, Grid } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTheme } from 'styled-components';
import { Container, Header, List, ListItem } from './style';
import { setDataFim } from '../../../../services/reducers/preferenceSlice';
import { useAppDispatch } from '../../../../services/reduxHooks';

interface ModalProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  data: string[] | null;
  dataSelecionada: string | undefined;
  ativos_nao_existem_carteira: boolean | null;
}

export default function ModalAviso({
  openModal,
  setOpenModal,
  data,
  dataSelecionada,
  ativos_nao_existem_carteira
}: ModalProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleClick = (item: string) => {
    dispatch(setDataFim((item.replaceAll("/", ""))));
  }
  const format_data = data?.map((item) => item.split("-").reverse().join("/"));
  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Container containerHeight={500}>
        <Header>
          <div>Explosão indisponível para data {dataSelecionada}<br/>
          {ativos_nao_existem_carteira ? null : (<span style={{fontWeight: "bold"}}>Datas disponíveis:</span>)}
          </div>

          <Close
            onClick={() => setOpenModal(false)}
            sx={{
              cursor: 'pointer',
              width: 20,
              [`@media ${theme.device.mobileXL}`]: {
                width: 15,
              },
              [`@media ${theme.device.mobileM}`]: {
                width: 10,
              },
            }}
          />
        </Header>
        <Grid container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {format_data && format_data.length > 0 ? (
          <List>
            {format_data.map((item, index) => (
              <ListItem key={index} onClick={() => handleClick(item)} style={{ cursor: 'pointer', padding: '10px', borderBottom: '1px solid #ccc', margin: '5px 0', width: '150px', textAlign: 'center' }}>
                {item}
              </ListItem>
            ))}
          </List>
        ) :
        ativos_nao_existem_carteira ? (<p>Nenhum ativo pode ser explodido</p>) : (<p>Nenhuma data disponível para explosão</p>)
        }
      </Grid>
      </Container>
    </Modal>
  );
}