import styled from 'styled-components';

export const ContainerInfo = styled.div`
  margin-top: 15px;
`;

export const BotaoAbrirModal = styled.button`
  display: inline;
  padding: 5px;
  margin-left: 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  font-size: ${(props) => props.theme.smFontSize};
  background-color: ${({ theme }) => theme.backgroundColorMain};
  color: ${({ theme }) => theme.backgroundColorSecondary};
  cursor: pointer;
`;

export const ContainerSubtitle = styled.h3`
  display: inline;
  font-weight: bold;
  text-align: 'left';
  color: ${({ theme }) => theme.titleFontColor};
  font-size: ${(props) => props.theme.smFontSize};
  margin-bottom: 8px;
`;