import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.backgroundColor};
  width: 55%;
  max-height: 65%;
  border-radius: 12px;
  outline: none;
  text-align: justify;
  padding: 40px;
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 18px;
  align: left;
`;

export const Disclaimer = styled.div`
    text-align: justify;
`;
