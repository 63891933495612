import styled from 'styled-components';

export const Container = styled.div<{ containerHeight: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.backgroundColor};
  width: 315px;
  border-radius: 12px;
  padding: ${({ theme }) => theme.mdPadding};
  outline: none;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0px;
  height: 300px;
  overflow-y: auto;
  margin: 0 auto;
  width: fit-content;
`;

export const ListItem = styled.li`
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #d6d6d6;
  margin: 5px 0;
  width: 150px;
  &:hover {
    background-color: #d6d6d6;
  }
`;
