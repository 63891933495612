/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState  } from 'react';
import { useTheme } from 'styled-components';
import { Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
// import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Line } from '../../../components/Line';
import Header from '../../../components/Header';
import EnhancedTable from '../../../components/Table';
import { AlignTypes, ITableColumns } from '../../../components/Table/TableHeader';
import { useAppDispatch, useAppSelector } from '../../../services/reduxHooks';
import {
  auxFormatPercent,
  FormatTypes,
  groupAndSumByProperties,
  helperFormat,
  sumByProperties,
} from '../../../services/helper';
import { fetchModalPosCon, fetchPosCon } from '../../../services/api/posConAPI';
import { CustomModal } from '../../../components/CustomModal';
import { Subtitulo } from '../../../components/Titulo';
import LoadingModal from '../LoadingModal';
import FixedLoadingButton from '../../../components/FixedLoadingButton';

import { sendAnalyticsData } from '../../../services/api/preferenceAPI';

import { fetchFluxoAtivos } from '../../../services/api/fluxoAtivosAPI';
import { fetchPerfHist } from '../../../services/api/perfHistAPI';
import { fetchResumoCart, fetchResumoCartPerf } from '../../../services/api/resumoCartAPI';
import { fetchIFsCaixa } from '../../../services/api/fluxoCaixaAPI';
import { fetchEstats } from '../../../services/api/estatsAPI';
import { fetchCartExp } from '../../../services/api/cartExpAPI';

interface Props {
  dispatchPDF?: boolean;
}

export default function PosicaoConsolidada({ dispatchPDF }: Props) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [
    obj_datas,
    dados_ativos_agrupados,
    datas_dados_ativos_agrupados,
    isLoaded,
    loading,
    dadosModal,
    loadingModal,
    loadedCarteiraList,
    isLoadingMinimized,
    params,
  ] = useAppSelector((state) => [
    state.posCon.obj_datas,
    state.posCon.dados_ativos_agrupados,
    state.posCon.datas_dados_ativos_agrupados,
    state.posCon.isLoaded,
    state.posCon.loading,
    state.posCon.dadosModal,
    state.posCon.loadingModal,
    state.preference.loadedCarteiraList,
    state.loadMinimized.isLoadingMinimized,
    state.preference.params,
  ]);

  const [openModal, setOpenModal] = useState(false);
  const [ativoModal, setAtivoModal] = useState(false);
  const [nomeAtivoModal, setNomeAtivoModal] = useState('');

  const onRowClickHandle = async (data: any) => {
    if (!openModal && ativoModal !== data.ativo) await dispatch(fetchModalPosCon(data));
    if (!openModal) setAtivoModal(data.ativo);
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (!dispatchPDF) document.title = `${process.env.REACT_APP_TABNAME} - Posição Consolidada`;
  }, []);

  useEffect(() => {
    if (isLoaded && !dispatchPDF) dispatch(sendAnalyticsData(location.href));
  }, [params, isLoaded]);

  useEffect(() => {
    async function loadDados() {
      await dispatch(fetchPosCon());
    }
    if (!isLoaded && !loading && !dispatchPDF) {
      loadDados();

      dispatch(fetchResumoCart());
      dispatch(fetchResumoCartPerf());
      dispatch(fetchPerfHist());
      dispatch(fetchCartExp());
      dispatch(fetchFluxoAtivos('mes_atual'));
      dispatch(fetchIFsCaixa());
      dispatch(fetchEstats());
    }
  }, [isLoaded]);

  const columnsDadosAgrupados: ITableColumns[] = [
    {
      id: 'nome_ativo',
      label: 'Ativo',
      colorLegend: true,
      freeze: true,
      width: '300px',
      noBreak: true,
    },
    {
      id: 'sbr_ini',
      label: `Saldo Bruto ${datas_dados_ativos_agrupados?.data_ini
        ?.split('-')
        .reverse()
        .join('/')}`,
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'apl',
      label: 'Aplicações no período',
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'res',
      label: 'Resgates no período',
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'evento',
      label: 'Eventos',
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'sbr_fim',
      label: `Saldo Bruto ${datas_dados_ativos_agrupados?.data_fim
        ?.split('-')
        .reverse()
        .join('/')}`,
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'rendimento',
      label: 'Rendimento Bruto',
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'imposto_pago',
      label: 'Impostos Pagos',
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'provisao_IR_IOF',
      label: 'Provisão IR+IOF',
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'sli_fim',
      label: 'Saldo Líquido',
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'percent_sli',
      label: '%',
      align: AlignTypes.right,
    },
  ];

  const dadosAgrupados =
    isLoaded && dados_ativos_agrupados
      ? groupAndSumByProperties(dados_ativos_agrupados, 'classe', 'subclasse', [
          'sbr_ini',
          'apl',
          'res',
          'evento',
          'sbr_fim',
          'rendimento',
          'imposto_pago',
          'provisao_IR_IOF',
          'sli_fim',
          'percent_sli',
        ])
      : [];

  const totalAtivos =
    isLoaded && dados_ativos_agrupados
      ? sumByProperties(dados_ativos_agrupados, [
          'sbr_ini',
          'apl',
          'res',
          'evento',
          'sbr_fim',
          'rendimento',
          'imposto_pago',
          'provisao_IR_IOF',
          'sli_fim',
          'percent_sli',
        ])
      : [];

  const rowsDadosAgrupados: any[] = [];
  dadosAgrupados.forEach((classe, index) => {
    // linha classe
    if (classe.class)
      rowsDadosAgrupados.push({
        nome_ativo: classe.class,
        colorIndex: index,
        bgColor: '#444444',
        color: theme.backgroundColor,
        bold: true,
        sbr_ini: '',
        apl: '',
        res: '',
        evento: '',
        sbr_fim: '',
        rendimento: '',
        imposto_pago: '',
        provisao_IR_IOF: '',
        sli_fim: '',
        percent_sli: '',
        openModal: null,
      });

    classe.subclasses.forEach((subclasse) => {
      // linha subclasse
      if (subclasse.subclass)
        rowsDadosAgrupados.push({
          nome_ativo: subclasse.subclass,
          bgColor: '#E7E7E7',
          color: theme.fontColor,
          bold: true,
          colorIndex: index,
          sbr_ini: '',
          apl: '',
          res: '',
          evento: '',
          sbr_fim: '',
          rendimento: '',
          imposto_pago: '',
          provisao_IR_IOF: '',
          sli_fim: '',
          percent_sli: '',
          openModal: null,
        });

      subclasse.values.forEach((ativo) => {
        // ativo
        rowsDadosAgrupados.push({
          nome_ativo: ativo.chave_para_modal?.data_compra_AAAAmmDD ? (
            <span>
              <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} style={{ marginRight: 4 }} />
              {ativo.nome_ativo}
            </span>
          ) : (
            ativo.nome_ativo
          ),
          sbr_ini: helperFormat(ativo.sbr_ini, FormatTypes.decimals, 2),
          apl: helperFormat(ativo.apl, FormatTypes.decimals, 2),
          res: helperFormat(ativo.res, FormatTypes.decimals, 2),
          evento: helperFormat(ativo.evento, FormatTypes.decimals, 2),
          sbr_fim: helperFormat(ativo.sbr_fim, FormatTypes.decimals, 2),
          rendimento: helperFormat(ativo.rendimento, FormatTypes.decimals, 2),
          imposto_pago: helperFormat(ativo.imposto_pago, FormatTypes.decimals, 2),
          provisao_IR_IOF: helperFormat(ativo.provisao_IR_IOF, FormatTypes.decimals, 2),
          sli_fim: helperFormat(ativo.sli_fim, FormatTypes.decimals, 2),
          percent_sli: helperFormat(auxFormatPercent(ativo.percent_sli), FormatTypes.decimals, 2),
          colorIndex: index,
          openModal: ativo.chave_para_modal?.data_compra_AAAAmmDD
            ? () => {
                onRowClickHandle(ativo.chave_para_modal);
                setNomeAtivoModal(ativo.nome_ativo);
              }
            : null,
          chave_para_modal: ativo.chave_para_modal,
        });
      });

      // total subclasse
      if (subclasse.subclass)
        rowsDadosAgrupados.push({
          nome_ativo: `Total ${subclasse.subclass}`,
          sbr_ini: helperFormat(subclasse.subtotal.sbr_ini, FormatTypes.decimals, 2),
          apl: helperFormat(subclasse.subtotal.apl, FormatTypes.decimals, 2),
          res: helperFormat(subclasse.subtotal.res, FormatTypes.decimals, 2),
          evento: helperFormat(subclasse.subtotal.evento, FormatTypes.decimals, 2),
          sbr_fim: helperFormat(subclasse.subtotal.sbr_fim, FormatTypes.decimals, 2),
          rendimento: helperFormat(subclasse.subtotal.rendimento, FormatTypes.decimals, 2),
          imposto_pago: helperFormat(subclasse.subtotal.imposto_pago, FormatTypes.decimals, 2),
          provisao_IR_IOF: helperFormat(
            subclasse.subtotal.provisao_IR_IOF,
            FormatTypes.decimals,
            2,
          ),
          sli_fim: helperFormat(subclasse.subtotal.sli_fim, FormatTypes.decimals, 2),
          percent_sli: helperFormat(
            auxFormatPercent(subclasse.subtotal.percent_sli),
            FormatTypes.decimals,
            2,
          ),
          colorIndex: index,
          bgColor: theme.chartLighterColors[index % theme.chartLighterColors.length],
          color: theme.fontColor,
          bold: true,
          openModal: null,
        });
    });

    // total classe
    if (classe.class)
      rowsDadosAgrupados.push({
        nome_ativo: `Total ${classe.class}`,
        sbr_ini: helperFormat(classe.subtotal.sbr_ini, FormatTypes.decimals, 2),
        apl: helperFormat(classe.subtotal.apl, FormatTypes.decimals, 2),
        res: helperFormat(classe.subtotal.res, FormatTypes.decimals, 2),
        evento: helperFormat(classe.subtotal.evento, FormatTypes.decimals, 2),
        sbr_fim: helperFormat(classe.subtotal.sbr_fim, FormatTypes.decimals, 2),
        rendimento: helperFormat(classe.subtotal.rendimento, FormatTypes.decimals, 2),
        imposto_pago: helperFormat(classe.subtotal.imposto_pago, FormatTypes.decimals, 2),
        provisao_IR_IOF: helperFormat(classe.subtotal.provisao_IR_IOF, FormatTypes.decimals, 2),
        sli_fim: helperFormat(classe.subtotal.sli_fim, FormatTypes.decimals, 2),
        percent_sli: helperFormat(
          auxFormatPercent(classe.subtotal.percent_sli),
          FormatTypes.decimals,
          2,
        ),
        colorIndex: index,
        bgColor: theme.chartLightColors[index % theme.chartLightColors.length],
        color: theme.fontColor,
        bold: true,
        openModal: null,
      });
  });
  if (isLoaded && dados_ativos_agrupados)
    rowsDadosAgrupados.push({
      nome_ativo: 'Total',
      bgColor: '#444444',
      color: theme.backgroundColor,
      bold: true,
      sbr_ini: helperFormat(totalAtivos.sbr_ini, FormatTypes.decimals, 2),
      apl: helperFormat(totalAtivos.apl, FormatTypes.decimals, 2),
      res: helperFormat(totalAtivos.res, FormatTypes.decimals, 2),
      evento: helperFormat(totalAtivos.evento, FormatTypes.decimals, 2),
      sbr_fim: helperFormat(totalAtivos.sbr_fim, FormatTypes.decimals, 2),
      rendimento: helperFormat(totalAtivos.rendimento, FormatTypes.decimals, 2),
      imposto_pago: helperFormat(totalAtivos.imposto_pago, FormatTypes.decimals, 2),
      provisao_IR_IOF: helperFormat(totalAtivos.provisao_IR_IOF, FormatTypes.decimals, 2),
      sli_fim: helperFormat(totalAtivos.sli_fim, FormatTypes.decimals, 2),
      percent_sli: helperFormat(100, FormatTypes.decimals, 2),
      colorIndex: null,
    });

  const rowsHistorico =
    dadosModal && !loadingModal
      ? dadosModal.slice(1).map((row) => {
          let i = 0;
          const dataAux = row[i++] as string;
          return {
            data: dataAux.split('-').reverse().join('/'),
            operacao: row[i++],
            quant: helperFormat(row[i++], FormatTypes.decimals, 0),
            preco: helperFormat(row[i++], FormatTypes.decimals, 2),
            valor: helperFormat(row[i++], FormatTypes.decimals, 2),
            quantTotal: helperFormat(row[i++], FormatTypes.decimals, 0),
            saldo: helperFormat(row[i++], FormatTypes.decimals, 2),
          };
        })
      : [];

  const columnsHistorico: ITableColumns[] = [
    {
      id: 'data',
      label: 'Data',
      freeze: true,
      align: AlignTypes.left,
    },
    {
      id: 'operacao',
      label: 'Operação',
      align: AlignTypes.left,
    },
    {
      id: 'quant',
      label: 'Quantidade',
      align: AlignTypes.right,
    },
    {
      id: 'preco',
      label: 'Preço do Ativo',
      align: AlignTypes.right,
    },
    {
      id: 'valor',
      label: 'Valor Bruto',
      align: AlignTypes.right,
    },
    {
      id: 'quantTotal',
      label: 'Quantidade Total',
      align: AlignTypes.right,
    },
    {
      id: 'saldo',
      label: 'Saldo Bruto',
      align: AlignTypes.right,
    },
  ];

  return (
    <>
      <Grid>
      <Header dataFim title="Posição Consolidada" obj_datas={obj_datas} data_fim_apenas/>
      <Line />
      </Grid>
      <Grid container spacing={3} sx={{ marginTop: 3 }}>
        <Grid item xs={12}>
          <EnhancedTable
            rows={rowsDadosAgrupados}
            columns={columnsDadosAgrupados}
            fontSize={12}
            height={400}
            noScroll={true}
            lineBreak={true}
            headerFixed={true}
          />
        </Grid>
      </Grid>
      <CustomModal open={openModal} onCloseHandle={onRowClickHandle}>
        <Subtitulo mb="8px">Histórico do Ativo - {nomeAtivoModal}</Subtitulo>
        <EnhancedTable rows={rowsHistorico} columns={columnsHistorico} height={400} />
      </CustomModal>
      <LoadingModal loading={((!loadedCarteiraList || !isLoaded || loadingModal) && !isLoadingMinimized) ?? false} />
      <FixedLoadingButton
        loading={(!loadedCarteiraList || !isLoaded || loadingModal) ?? false}
        isLoadingMinimized={isLoadingMinimized}
      />
    </>
  );
}
